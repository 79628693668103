$(document).ready(function () {
  var elems = document.querySelectorAll("#welcome-modal");

  if (elems.length == 0) {
    return true
  }

  M.Modal.init(elems, { dismissible: false, inDuration: 250, outDuration: 250 });
  elems[0].M_Modal.open();

  $(".modal-action").click(function () {
    $.ajax({
      type: 'PUT',
      url: "/stores/update",
      data: {
        'store': {
          'visualized_welcome': true,
        }
      }
    });
  })
});
