$(document).on("turbolinks:load", function () {
  // exportação de csv
  $("#preview-mailer").click(function () {
    $.ajax({
      type: 'POST',
      url: "/preview_promotional_mailers",
      dataType: 'json',
      success: function (data, text, jqXHR) {
        responseText = 'Verifique a caixa de entrada do seu e-mail'
        return M.toast({
          html: responseText
        });
      },
      error: function (jqXHR, textStatus, errorThrown) {
        var responseText = JSON.parse(jqXHR.responseText);
        return M.toast({
          html: responseText
        });
      }
    });
    return false;
  })
})