$(document).on("turbolinks:load", () => {
  $(document.getElementById('check_all')).on('change', () => {
    let checkBoxes = document.getElementsByClassName("check-stores")
    let checked = document.getElementById("check_all").checked

    for (let i = 0; i < checkBoxes.length; i++) {
      checkBoxes[i].checked = checked;
    }
  })

  $(document.getElementById('multi-sync-button')).on('click', () => {
    let checkBoxes = document.getElementsByClassName("check-stores")

    for (let i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes[i].checked) {
        $.ajax({
          type: 'POST',
          url: '/admin/store_synchronize',
          data: {
            'store_user_id': checkBoxes[i].id
          },
        }).done(() => {
          if (i === checkBoxes.length - 1) alert('Lojas sincronizadas')
        })
      }
    }
  })
})

$(document).on('click', '#store-only-deleted', function () {
  $.ajax({
    type: 'GET',
    url: '/admin/stores',
    data: {
      'only_deleted': true
    },
  })
})

$(document).on('click', '#restore', function () {
  let id = $('#restore').attr('data-id')
  $.ajax({
    type: 'PUT',
    url: '/admin/store_restores/'+id
  })
})

$(document).on('click', '#submit-search', function () {
  let url = '/admin/'+$('#submit-search').attr('data-routes')+'?search='+$('#search').val()
  $.ajax({
    type: 'GET',
    url: url
  })
})

$(document).on('change', '#select-search', function () {
  let url = '/admin/'+$('#select-search').attr('data-routes')+'?search='+$('#filters').val()
  $.ajax({
    type: 'GET',
    url: url
  })
})

$(document).on('click', '#opted_out', function () {
  $.ajax({
    type: 'PUT',
    url: '/admin/customers/'+this.value,
    data: {
      'customer': {
        'opted_out': this.checked
      }
    }
  })
})
