$(document).on( 'click', '#refresh-status',  function(e) {
  M.Toast.dismissAll();

  $.ajax({
    type: 'PUT',
    url: "/stores/"+$(this).attr('data-store')+"/refresh_status",
    success: function(data, text, jqXHR) {
      var responseText = jQuery.parseJSON(jqXHR.responseText);
      setTimeout(function(){
        location.reload();
      }, 500);
      return M.toast( { html: responseText['message'] });
    },
    error: function(jqXHR, textStatus, errorThrown) {
      var responseText = jQuery.parseJSON(jqXHR.responseText);
      return M.toast( { html: responseText } );
    }
  });
  return false;
})