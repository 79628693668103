$(document).on("turbolinks:load", function () {
  // exportação de csv
  $('.character-counter').remove()

  $(".btn-exports").click(function () {
    var _selector = document.querySelectorAll('input:checked'); //Pega todos checkbox
    var suspended = $(this).attr('data-suspended');

    if (suspended == 'true') {
      return alert('Regularize sua conta para continuar')
    }

    if (_selector.length > 0) { //Seleciona os ids dos items que que serão adicionados no xls
      var ids = []
      for (var i = 0; i < _selector.length; i++) { //cria o array de ids para filtrar na controller
        ids.push(_selector[i].id.replace('check_card_', ''))
      }
    } else {
      return alert('Selecione um ou mais items')
    }
    window.open('/export_wish_lists?format=csv&ids=' + ids, '_blank') //controller que gera o arquivo, usa os ids para gerar o arquivo
  })

  //Trabalha no css dos card quando um item é selecionado
  $(document).on('click', '.card-list', function () {
    var contents = document.getElementById(this.id) //Pega o elemento selecionado
    var cheked = $("#check_" + this.id).is(":checked") //Pega o checkbox do card selecionado

    if (cheked) { //Adiciona css para sinalizar que o item está selecionado
      contents.style.backgroundColor = "rgba(252, 15, 135, 0.05)"
      contents.style.border = "solid 1px #fc0f87"
    } else { //Remove o css adicionado se tirar o check do item
      contents.style.backgroundColor = ""
      contents.style.border = ""
    }
  })

  //Seleciona todos os items da tabela
  $(document).on('change', 'th', function () {
    var cheked = $(this).find('input[type="checkbox"]').is(':checked'); //pega o checkbox dentro do elemento
    var _selector = $('tr').find('input[type="checkbox"]');
    var i = 1;
    if (cheked) {
      for (i; i <= _selector.length - 1; i++) {
        _selector[i].checked = true
      }
    } else {
      for (i; i <= _selector.length - 1; i++) {
        _selector[i].checked = false
      }
    }
  })

  //Seleciona todos os items do card
  $(document).on('change', '.switch', function () {
    var cheked = $(this).find("input[type=checkbox]").prop('checked');
    var _selector = $('.card-list').find('input[type="checkbox"]');
    var i = 0;
    if (cheked) {
      for (i; i <= _selector.length - 1; i++) {
        _selector[i].checked = true
        _selector.parents('.card-list').css({"background-color": "rgba(252, 15, 135, 0.05)"})
        _selector.parents('.card-list').css({"border": "solid 1px #fc0f87"})


        $(".btn-exports").removeClass('blue-grey lighten-3')
        $(".btn-exports").css({"background-color": "#fc0f87"})
      }
    } else {
      for (i; i <= _selector.length - 1; i++) {
        _selector[i].checked = false
        _selector.parents('.card-list').css({"background-color": ""})
        _selector.parents('.card-list').css({"border": ""})
        $(".btn-exports").css({"background-color": "#bdbdbd"})
      }
    }
  })

  //Trabalha no css do botão de exportação
  $(document).on('change', '.card-list', function () {
    var _selector = document.querySelectorAll('input:checked'); //Pega todos checkbox
    if (_selector.length > 0) { //Verifica se existe items marcados
      $(".btn-exports").removeClass('blue-grey lighten-3')
      $(".btn-exports").css({
        "background-color": "#fc0f87"
      }) //Adiciona nova cor sinalizando que pode exportar
    } else {
      $(".btn-exports").css({
        "background-color": "#bdbdbd"
      }) //Adiciona a sinalizando que não pode exportar
    }
  })

  //Trabalha no css do botão de exportação
  $(document).on('change', 'tr', function () {
    var _selector = document.querySelectorAll('input:checked'); //Pega todos checkbox
    if (_selector.length > 0) { //Verifica se existe items marcados
      $(".btn-exports").removeClass('blue-grey lighten-3')
      $(".btn-exports").css({
        "background-color": "#fc0f87"
      }) //Adiciona nova cor sinalizando que pode exportar
    } else {
      $(".btn-exports").css({
        "background-color": "#bdbdbd"
      }) //Adiciona a sinalizando que não pode exportar
    }
  })

  //Renderiza a lista de cards
  $("#show-card-list").click(function () {
    $("#append-list").removeClass('table-visible')
    $("#append-list").addClass('card-visible')
    $('.switch').removeClass('hide')
    $('#product-filter').removeClass('hide')
    $('#product-filter-label').removeClass('hide')

    $(".table-filter").removeClass('hide')
    $(".table-filter-pink").addClass('hide')

    $(".card-filter").addClass('hide')
    $(".card-filter-pink").removeClass('hide')

    $(".btn-exports").css({
      "background-color": "#bdbdbd"
    })
    $.ajax({
      type: 'GET',
      url: '/products'
    })
  })

  //Renderiza a lista de tabela
  $("#show-table-list").on('click', function () {
    $("#append-list").removeClass('card-visible')
    $("#append-list").addClass('table-visible')
    $('.switch').addClass('hide')
    $('#product-filter').addClass('hide')
    $('#product-filter-label').addClass('hide')

    $(".card-filter").removeClass('hide')
    $(".card-filter-pink").addClass('hide')

    $(".table-filter-pink").removeClass('hide')
    $(".table-filter").addClass('hide')

    $(".btn-exports").css({
      "background-color": "#bdbdbd"
    })
  })

  $(document).on('change', '#product-filter', function () {
    $("#append-list").addClass('card-visible')

    $.ajax({
      type: 'GET',
      url: "/products?search="+$(this).val(),
    });
  })
})