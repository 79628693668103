$(document).on('change', '.update_store',  function(e) {
  M.Toast.dismissAll();
  $.ajax({
    type: 'PUT',
    url: "/stores/update",
    dataType: 'json',
    data: {'store': { 'promotional_notification': $('#store_promotional_notification').is(":checked")} },
    success: function(data, text, jqXHR) {
      return M.toast( { html: 'Alteração salva!' });
    },
    error: function(jqXHR, textStatus, errorThrown) {
      var responseText = jQuery.parseJSON(jqXHR.responseText);
      return M.toast( { html: responseText } );
    }
  });
  return false;
})