$(document).on('change', '.update_svg_button', function (e) {
  return update($(this))
})

$(document).on('change', '.update_svg_button_active', function (e) {
  return update($(this))
})

$(document).on('change', '.update_svg_icon_mobile', function (e) {
  return update($(this))
})

$(document).on('change', '.update_svg_icon', function (e) {
  return update($(this))
})

function update(element) {
  $.ajax({
    type: 'PUT',
    url: element.attr('action'),
    data: element.serialize(),
    success: function (data, text, jqXHR) {
      return M.toast({
        html: 'Alteração salva!'
      });
    },
    error: function (jqXHR, textStatus, errorThrown) {
      var responseText = jQuery.parseJSON(jqXHR.responseText);
      return M.toast({
        html: responseText
      });
    }
  });
  return false;
}

$(document).on('click', '.pcr-save', function (e) {
  M.Toast.dismissAll();
  if ($("#button").hasClass('active')) {
    url = $('.update_svg_button').attr('action')
    data = $('.update_svg_button').serialize()
  } else if ($("#button-active").hasClass('active')) {
    url = $('.update_svg_button_active').attr('action')
    data = $('.update_svg_button_active').serialize()
  } else if ($("#menu-icon").hasClass('active')) {
    url = $('.update_svg_icon').attr('action')
    data = $('.update_svg_icon').serialize()
  } else if ($("#menu-icon-mobile").hasClass('active')) {
    url = $('.update_svg_icon_mobile').attr('action')
    data = $('.update_svg_icon_mobile').serialize()
  }

  $.ajax({
    type: 'PUT',
    url: url,
    data: data,
    success: function (data, text, jqXHR) {
      return M.toast({
        html: 'Alteração salva!'
      });
    },
    error: function (jqXHR, textStatus, errorThrown) {
      var responseText = jQuery.parseJSON(jqXHR.responseText);
      return M.toast({
        html: responseText
      });
    }
  });
  return false;
})