import {
  Controller
} from "stimulus";

export default class extends Controller {
  static targets = ["input", "object"]

  connect() {
    $('.character-counter').remove()

    this.control_icon('icon')
    this.control_icon('button')
    this.control_icon('button_active')

    this.control_text('icon')
    this.control_text('button')
    this.control_text('button_active')

    this.control_text_bold('icon')
    this.control_text_bold('button')
    this.control_text_bold('button_active')

    this.control_border('button')
    this.control_border('button_active')
  }

  update(event) {
    if (event.target == this.inputTarget) {
      this.objectTarget.checked = false
    } else {
      this.inputTarget.checked = false
      this.enabled(event)
    }
  }

  control_icon(type) {
    let icon = document.getElementById('original_icon_' + type)
    let icon_box = document.getElementById('check_icon_' + type)

    if (icon != null && !icon.checked) {
      icon_box.checked = true
      if (document.getElementById('check_icon_' + type) != null) {
        document.getElementById('icon_fill_color_' + type).disabled = true;
        document.getElementById('icon_border_color_' + type).disabled = true;
        document.getElementById('icon_border_width_' + type).disabled = true;
      }
    }
  }

  control_text(type) {
    let text = document.getElementById('original_text_' + type)
    let text_box = document.getElementById('check_text_' + type)

    if (text != null && !text.checked) {
      text_box.checked = true
      document.getElementById('text_color_' + type).disabled = true;
      document.getElementById('text_position_' + type).disabled = true;
      document.getElementById('text_width_' + type).disabled = true;
      document.getElementById('description_' + type).disabled = true;
      document.getElementById('original_text_bold_' + type).disabled = true;
      document.getElementById('check_text_bold_' + type).disabled = true;
    }
  }

  control_text_bold(type) {
    let text = document.getElementById('original_text_' + type)
    let text_bold = document.getElementById('original_text_bold_' + type)
    let text_bold_box = document.getElementById('check_text_bold_' + type)

    if (text_bold != null && !text_bold.checked && text.checked) {
      text_bold_box.checked = true
    }
  }

  control_border(type) {
    let border = document.getElementById('original_border_' + type)
    let border_box = document.getElementById('check_border_' + type)

    if (border != null && !border.checked) {
      border_box.checked = true
      document.getElementById('border_color_' + type).disabled = true;
      document.getElementById('fill_color_' + type).disabled = true;
    }
  }

  enabled(event) {
    this.enable_icon('icon')
    this.enable_icon('button')
    this.enable_icon('button_active')

    this.enable_text('icon')
    this.enable_text('button')
    this.enable_text('button_active')

    this.enable_border('button')
    this.enable_border('button_active')
  }

  enable_icon(type) {
    let icon = document.getElementById('check_icon_' + type)

    if (icon == null) {
      return true
    }

    if (!icon.checked) {
      document.getElementById('icon_fill_color_' + type).disabled = false;
      document.getElementById('icon_border_color_' + type).disabled = false;
      document.getElementById('icon_border_width_' + type).disabled = false;
    }
  }

  enable_text(type) {
    let text = document.getElementById('check_text_' + type)

    if (text == null) {
      return true
    }

    if (!text.checked) {
      document.getElementById('text_color_' + type).disabled = false;
      document.getElementById('text_position_' + type).disabled = false;
      document.getElementById('text_width_' + type).disabled = false;
      document.getElementById('description_' + type).disabled = false;
      document.getElementById('original_text_bold_' + type).disabled = false;
      document.getElementById('check_text_bold_' + type).disabled = false;
    }
  }

  enable_border(type) {
    let border = document.getElementById('check_border_' + type)

    if (border == null) {
      return true
    }

    if (!border.checked) {
      document.getElementById('border_color_' + type).disabled = false;
      document.getElementById('fill_color_' + type).disabled = false;
    }
  }
}