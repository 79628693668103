
$(document).on('click', '.toggle-suspended', (e) => {
  e.preventDefault();
  const storeId = $(e.currentTarget).attr('data-store-id');

  $.ajax({
    dataType: 'json',
    type: 'PUT',
    url: `/admin/stores/${storeId}/toggle_suspended`,
  }).done((res) => {
    const toggleSuspendedLabel = document.getElementById("toggle_suspended_label");
    const suspended_text = res['suspended'] ? 'Sim' : 'Não';
    $(toggleSuspendedLabel).text(suspended_text);
  });

});
