$(document).on( 'click', '#validate-anchors',  function(e) {
  M.Toast.dismissAll();
  $.ajax({
    type: 'PUT',
    url: "/stores/update",
    dataType: 'json',
    data: {'store': { 'validate_anchors': true } },
    success: function(data, text, jqXHR) {
      window.location.reload();
    },
    error: function(jqXHR, textStatus, errorThrown) {
      var responseText = jQuery.parseJSON(jqXHR.responseText);
      return M.toast( { html: responseText } );
    }
  });
  return false;
})
