//função para criar os dropdowns
$(document).on("turbolinks:load", function () {
  var dropdowns = document.querySelectorAll('.dropdown-trigger')
  for (var i = 0; i < dropdowns.length; i++) {
    M.Dropdown.init(dropdowns[i]);
  }
})

window.onbeforeunload = function (e) {
  if (window.location.pathname == "users/cadastro"){
    e = e || window.event;
    if (e) {
      e.returnValue = 'É possível que as alterações feitas não sejam salvas.'
    }
    return 'É possível que as alterações feitas não sejam salvas.'
  }
};