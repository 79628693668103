$(document).on('click', '#svg_restore_button', function (e) {
  svg_id = $(this).attr("data-id")
  svg_type = $('#svg_type_button').attr("value")
  update_icons(svg_type, svg_id)
})

$(document).on('click', '#svg_restore_button_active', function (e) {
  svg_id = $(this).attr("data-id")
  svg_type = $('#svg_type_button_active').attr("value")
  update_icons(svg_type, svg_id)
})

$(document).on('click', '#svg_restore_icon', function (e) {
  svg_type = $('#svg_type_icon').attr("value")
  svg_id = $(this).attr("data-id")
  update_icons(svg_type, svg_id)
})

$(document).on('click', '#svg_restore_icon_mobile', function (e) {
  svg_type = $('#svg_type_icon_mobile').attr("value")
  svg_id = $(this).attr("data-id")
  update_icons(svg_type, svg_id)
})

function update_icons(svg_type, svg_id) {
  M.Toast.dismissAll();

  $.ajax({
    type: 'PUT',
    url: "/svg_stores/"+svg_id,
    dataType: 'json',
    data: {
      'svg_store': {
        'default': true,
        'svg_type': svg_type,
        'id': svg_id
      }
    }
  });

  setTimeout(function(){
    location.reload();
  }, 500);
}