require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

import $ from 'jquery'
window.jQuery = $;
window.$ = $;

require('globals.js')
require('product/index.js')
require('admin/store/index.js')
require('admin/store/show.js')
require('store/update.js')
require('store/welcome.js')
require('store/check_anchors.js')
require("stimulus")
require('controllers/index.js')
require('preview_mailers/create.js')
require('svg_store/restore.js')
require('store/refresh_status.js')
require('svg_store/update.js')